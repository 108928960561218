import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, Inject, ChangeDetectorRef, inject, InjectFlags, Component, ChangeDetectionStrategy, Optional, Self, ViewChild, Input, HostBinding, HostListener, NgModule } from '@angular/core';
import * as i8 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { MASKITO_DEFAULT_OPTIONS } from '@maskito/core';
import { maskitoDateOptionsGenerator } from '@maskito/kit';
import * as i2 from '@taiga-ui/cdk';
import { DATE_FILLER_LENGTH, TuiDay, TUI_DATE_FORMAT, AbstractTuiNullableControl, ALWAYS_FALSE_HANDLER, TuiMonth, changeDateSeparator, tuiDateClamp, tuiNullableSame, TUI_IS_MOBILE, TUI_DATE_SEPARATOR, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure, TuiPreventDefaultModule, TuiLetModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_TEXTFIELD_HOST, TUI_DEFAULT_MARKER_HANDLER, TUI_TEXTFIELD_SIZE, TUI_DROPDOWN_COMPONENT, TuiPrimitiveTextfieldComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiWrapperModule, TuiCalendarModule, TuiSvgModule, TuiLinkModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiTextfieldComponent } from '@taiga-ui/core';
import { TUI_DATE_MODE_MASKITO_ADAPTER } from '@taiga-ui/kit/constants';
import { TUI_MOBILE_CALENDAR, TUI_DATE_TEXTS, TUI_DATE_VALUE_TRANSFORMER, TUI_INPUT_DATE_OPTIONS, tuiDateStreamWithTransformer } from '@taiga-ui/kit/tokens';
import { map } from 'rxjs/operators';
import * as i3 from '@taiga-ui/kit/directives';
import { TuiValueAccessorModule } from '@taiga-ui/kit/directives';
import * as i4 from '@maskito/angular';
import { MaskitoModule } from '@maskito/angular';
import * as i5 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i6 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i9 from 'rxjs';
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
const _c2 = a0 => ({
  $implicit: a0
});
function TuiInputDateComponent_tui_primitive_textfield_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-primitive-textfield", 5);
    i0.ɵɵlistener("focusedChange", function TuiInputDateComponent_tui_primitive_textfield_1_Template_tui_primitive_textfield_focusedChange_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onFocused($event));
    })("valueChange", function TuiInputDateComponent_tui_primitive_textfield_1_Template_tui_primitive_textfield_valueChange_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onValueChange($event));
    });
    i0.ɵɵprojection(1);
    i0.ɵɵprojection(2, 1, ["ngProjectAs", "input", 5, ["input"]]);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const filler_r4 = ctx.tuiLet;
    const ctx_r2 = i0.ɵɵnextContext();
    const iconContent_r5 = i0.ɵɵreference(4);
    i0.ɵɵproperty("disabled", ctx_r2.computedDisabled)("focusable", ctx_r2.computedFocusable)("invalid", ctx_r2.computedInvalid)("maskito", ctx_r2.computedMask)("nativeId", ctx_r2.nativeId)("pseudoFocus", ctx_r2.pseudoFocus)("pseudoHover", ctx_r2.pseudoHover)("readOnly", ctx_r2.readOnly)("tuiTextfieldFiller", ctx_r2.getComputedFiller(filler_r4 || ""))("tuiTextfieldIcon", ctx_r2.calendarIcon && iconContent_r5)("value", ctx_r2.computedValue);
  }
}
function TuiInputDateComponent_ng_template_3_tui_svg_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-svg", 8);
    i0.ɵɵlistener("click", function TuiInputDateComponent_ng_template_3_tui_svg_0_Template_tui_svg_click_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.onIconClick());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const src_r7 = ctx.polymorpheusOutlet;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("t-icon", !ctx_r2.computedDisabled);
    i0.ɵɵproperty("src", src_r7);
  }
}
function TuiInputDateComponent_ng_template_3_input_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 9);
  }
}
function TuiInputDateComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TuiInputDateComponent_ng_template_3_tui_svg_0_Template, 1, 3, "tui-svg", 6)(1, TuiInputDateComponent_ng_template_3_input_1_Template, 1, 0, "input", 7);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.calendarIcon)("polymorpheusOutletContext", i0.ɵɵpureFunction1(3, _c2, ctx_r2.size));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.nativePicker);
  }
}
function TuiInputDateComponent_ng_template_5_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12)(1, "button", 13);
    i0.ɵɵlistener("click", function TuiInputDateComponent_ng_template_5_div_1_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.onDayClick(ctx_r2.items[0].day));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r2.items[0], " ");
  }
}
function TuiInputDateComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-calendar", 10);
    i0.ɵɵlistener("dayClick", function TuiInputDateComponent_ng_template_5_Template_tui_calendar_dayClick_0_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onDayClick($event));
    })("monthChange", function TuiInputDateComponent_ng_template_5_Template_tui_calendar_monthChange_0_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onMonthChange($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(1, TuiInputDateComponent_ng_template_5_div_1_Template, 3, 1, "div", 11);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabledItemHandler", ctx_r2.disabledItemHandler)("markerHandler", ctx_r2.markerHandler)("max", ctx_r2.computedMax)("min", ctx_r2.computedMin)("month", ctx_r2.computedActiveYearMonth)("value", ctx_r2.value);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.items.length === 1);
  }
}
class TuiNativeDateDirective {
  constructor(host, dateFormat) {
    this.host = host;
    this.dateFormat = dateFormat;
  }
  get value() {
    return this.host.value.length === DATE_FILLER_LENGTH ? TuiDay.normalizeParse(this.host.value, this.dateFormat).toString('YMD', '-') : '';
  }
  get max() {
    return this.host.max.toJSON();
  }
  get min() {
    return this.host.min.toJSON();
  }
  onChange(value) {
    this.host.onValueChange(value ? TuiDay.normalizeParse(value, 'YMD').toString(this.dateFormat) : '');
  }
}
TuiNativeDateDirective.ɵfac = function TuiNativeDateDirective_Factory(t) {
  return new (t || TuiNativeDateDirective)(i0.ɵɵdirectiveInject(TUI_TEXTFIELD_HOST), i0.ɵɵdirectiveInject(TUI_DATE_FORMAT));
};
TuiNativeDateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiNativeDateDirective,
  selectors: [["input", "tuiDate", ""]],
  hostAttrs: ["type", "date"],
  hostVars: 4,
  hostBindings: function TuiNativeDateDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function TuiNativeDateDirective_change_HostBindingHandler($event) {
        return ctx.onChange($event.target.value);
      })("click.stop.silent", function TuiNativeDateDirective_click_stop_silent_HostBindingHandler() {
        return 0;
      })("input.stop.silent", function TuiNativeDateDirective_input_stop_silent_HostBindingHandler() {
        return 0;
      })("mousedown.stop.silent", function TuiNativeDateDirective_mousedown_stop_silent_HostBindingHandler() {
        return 0;
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("tabIndex", -1)("value", ctx.value)("max", ctx.max)("min", ctx.min);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeDateDirective, [{
    type: Directive,
    args: [{
      selector: 'input[tuiDate]',
      host: {
        type: 'date',
        '[tabIndex]': '-1',
        '[value]': 'value',
        '(change)': 'onChange($event.target.value)',
        '[max]': 'max',
        '[min]': 'min',
        '(click.stop.silent)': '0',
        '(input.stop.silent)': '0',
        '(mousedown.stop.silent)': '0'
      }
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_HOST]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_FORMAT]
      }]
    }];
  }, null);
})();
class TuiInputDateComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, isMobile, mobileCalendar, dateFormat, dateSeparator, dateTexts$, valueTransformer, options, textfieldSize) {
    super(control, cdr, valueTransformer);
    this.isMobile = isMobile;
    this.mobileCalendar = mobileCalendar;
    this.dateFormat = dateFormat;
    this.dateSeparator = dateSeparator;
    this.dateTexts$ = dateTexts$;
    this.valueTransformer = valueTransformer;
    this.options = options;
    this.textfieldSize = textfieldSize;
    this.month = null;
    this.min = this.options.min;
    this.max = this.options.max;
    this.disabledItemHandler = ALWAYS_FALSE_HANDLER;
    this.markerHandler = TUI_DEFAULT_MARKER_HANDLER;
    this.items = [];
    this.defaultActiveYearMonth = TuiMonth.currentLocal();
    this.open = false;
    this.filler$ = this.dateTexts$.pipe(map(dateTexts => changeDateSeparator(dateTexts[this.dateFormat], this.dateSeparator)));
  }
  get size() {
    return this.textfieldSize.size;
  }
  get computedMin() {
    var _a;
    return (_a = this.min) !== null && _a !== void 0 ? _a : this.options.min;
  }
  get computedMax() {
    var _a;
    return (_a = this.max) !== null && _a !== void 0 ? _a : this.options.max;
  }
  get nativeFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.textfield) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) !== null && _b !== void 0 ? _b : null;
  }
  get focused() {
    var _a;
    return !!((_a = this.textfield) === null || _a === void 0 ? void 0 : _a.focused);
  }
  /**
   * @deprecated
   */
  get computedMobile() {
    return this.isMobile && (!!this.mobileCalendar || this.nativePicker);
  }
  get nativePicker() {
    return this.options.nativePicker && !!this.mobileCalendar && this.isMobile;
  }
  get calendarIcon() {
    return this.options.icon;
  }
  get computedValue() {
    const {
      value,
      nativeValue,
      activeItem
    } = this;
    if (activeItem) {
      return String(activeItem);
    }
    return value ? value.toString(this.dateFormat, this.dateSeparator) : nativeValue;
  }
  get computedActiveYearMonth() {
    if (this.items[0] && this.value && this.value.daySame(this.items[0].day)) {
      return this.items[0].displayDay;
    }
    return this.month || this.value || tuiDateClamp(this.defaultActiveYearMonth, this.computedMin, this.computedMax);
  }
  get nativeValue() {
    var _a;
    return ((_a = this.nativeFocusableElement) === null || _a === void 0 ? void 0 : _a.value) || '';
  }
  set nativeValue(value) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.value = value;
    }
  }
  get computedMask() {
    return this.activeItem ? MASKITO_DEFAULT_OPTIONS : this.computeMaskOptions(this.dateFormat, this.dateSeparator, this.computedMin, this.computedMax);
  }
  get activeItem() {
    const {
      value
    } = this;
    return value && this.items.find(item => item.day.daySame(value)) || null;
  }
  onClick() {
    if (!this.isMobile && this.interactive) {
      this.open = !this.open;
    }
  }
  getComputedFiller(filler) {
    return this.activeItem ? '' : filler;
  }
  // noinspection JSUnusedGlobalSymbols
  /**
   * TODO: Remove in 4.0
   * @deprecated: use {@link onIconClick} instead
   */
  onMobileClick() {
    this.onIconClick();
  }
  onIconClick() {
    if (this.isMobile && this.interactive) {
      this.open = true;
    }
  }
  onValueChange(value) {
    if (this.control) {
      this.control.updateValueAndValidity({
        emitEvent: false
      });
    }
    if (!value && !this.mobileCalendar) {
      this.onOpenChange(true);
    }
    this.value = value.length !== DATE_FILLER_LENGTH ? null : TuiDay.normalizeParse(value, this.dateFormat);
  }
  onDayClick(value) {
    this.value = value;
    this.open = false;
  }
  onMonthChange(month) {
    this.month = month;
  }
  onOpenChange(open) {
    this.open = open;
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  setDisabledState() {
    super.setDisabledState();
    this.open = false;
  }
  writeValue(value) {
    super.writeValue(value);
    this.nativeValue = value ? this.computedValue : '';
  }
  valueIdenticalComparator(oldValue, newValue) {
    return tuiNullableSame(oldValue, newValue, (a, b) => a.daySame(b));
  }
  computeMaskOptions(mode, separator, min, max) {
    return maskitoDateOptionsGenerator({
      separator,
      mode: TUI_DATE_MODE_MASKITO_ADAPTER[mode],
      min: min.toLocalNativeDate(),
      max: max.toLocalNativeDate()
    });
  }
}
TuiInputDateComponent.ɵfac = function TuiInputDateComponent_Factory(t) {
  return new (t || TuiInputDateComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_IS_MOBILE), i0.ɵɵdirectiveInject(TUI_MOBILE_CALENDAR, 8), i0.ɵɵdirectiveInject(TUI_DATE_FORMAT), i0.ɵɵdirectiveInject(TUI_DATE_SEPARATOR), i0.ɵɵdirectiveInject(TUI_DATE_TEXTS), i0.ɵɵdirectiveInject(TUI_DATE_VALUE_TRANSFORMER, 8), i0.ɵɵdirectiveInject(TUI_INPUT_DATE_OPTIONS), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE));
};
TuiInputDateComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputDateComponent,
  selectors: [["tui-input-date", 3, "multiple", ""]],
  viewQuery: function TuiInputDateComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiInputDateComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function TuiInputDateComponent_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    min: "min",
    max: "max",
    disabledItemHandler: "disabledItemHandler",
    markerHandler: "markerHandler",
    items: "items",
    defaultActiveYearMonth: "defaultActiveYearMonth"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputDateComponent), tuiAsControl(TuiInputDateComponent), tuiDateStreamWithTransformer(TUI_DATE_VALUE_TRANSFORMER), {
    provide: TUI_DROPDOWN_COMPONENT,
    useFactory: () => inject(TUI_IS_MOBILE) && inject(TUI_MOBILE_CALENDAR, InjectFlags.Optional) || inject(TUI_DROPDOWN_COMPONENT, InjectFlags.SkipSelf)
  }]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 7,
  vars: 7,
  consts: [["iconContent", ""], ["dropdown", "polymorpheus"], [1, "t-hosted", 3, "openChange", "canOpen", "content", "open"], ["automation-id", "tui-input-date-range__textfield", "tuiValueAccessor", "", "class", "t-textfield", 3, "disabled", "focusable", "invalid", "maskito", "nativeId", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldFiller", "tuiTextfieldIcon", "value", "focusedChange", "valueChange", 4, "tuiLet"], [3, "polymorpheus"], ["automation-id", "tui-input-date-range__textfield", "tuiValueAccessor", "", 1, "t-textfield", 3, "focusedChange", "valueChange", "disabled", "focusable", "invalid", "maskito", "nativeId", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldFiller", "tuiTextfieldIcon", "value"], ["appearance", "icon", "automation-id", "tui-input-date-range__icon", "tuiWrapper", "", 3, "t-icon", "src", "click", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["tuiDate", "", "class", "t-native-input", 4, "ngIf"], ["appearance", "icon", "automation-id", "tui-input-date-range__icon", "tuiWrapper", "", 3, "click", "src"], ["tuiDate", "", 1, "t-native-input"], ["automation-id", "tui-input-date__calendar", "tuiPreventDefault", "mousedown", 3, "dayClick", "monthChange", "disabledItemHandler", "markerHandler", "max", "min", "month", "value"], ["tuiPreventDefault", "mousedown", "class", "t-button", 4, "ngIf"], ["tuiPreventDefault", "mousedown", 1, "t-button"], ["tuiLink", "", "type", "button", 3, "click"]],
  template: function TuiInputDateComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "tui-hosted-dropdown", 2);
      i0.ɵɵlistener("openChange", function TuiInputDateComponent_Template_tui_hosted_dropdown_openChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onOpenChange($event));
      });
      i0.ɵɵtemplate(1, TuiInputDateComponent_tui_primitive_textfield_1_Template, 3, 11, "tui-primitive-textfield", 3);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵtemplate(3, TuiInputDateComponent_ng_template_3_Template, 2, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(5, TuiInputDateComponent_ng_template_5_Template, 2, 7, "ng-template", 4, 1, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const dropdown_r10 = i0.ɵɵreference(6);
      i0.ɵɵproperty("canOpen", ctx.interactive && !ctx.nativePicker)("content", dropdown_r10)("open", ctx.open && ctx.interactive && !ctx.nativePicker);
      i0.ɵɵadvance();
      i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(2, 5, ctx.filler$));
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("polymorpheus", ctx.type);
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i1.TuiPrimitiveTextfieldComponent, i1.TuiSvgComponent, i1.TuiCalendarComponent, i1.TuiLinkComponent, i2.TuiLetDirective, i1.TuiPrimitiveTextfieldDirective, i3.TuiValueAccessorDirective, i4.MaskitoDirective, i1.TuiTextfieldFillerDirective, i1.TuiTextfieldIconDirective, i5.PolymorpheusOutletDirective, i1.TuiWrapperDirective, i6.NgIf, TuiNativeDateDirective, i5.PolymorpheusTemplate, i2.TuiPreventDefaultDirective, i6.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}.t-icon[_ngcontent-%COMP%]{pointer-events:auto}.t-button[_ngcontent-%COMP%]{display:flex;height:2.75rem;justify-content:center;box-shadow:inset 0 1px var(--tui-base-03)}.t-button[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{flex:1;text-align:center}.t-native-input[_ngcontent-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;pointer-events:auto;font-size:2rem}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiInputDateComponent.prototype, "computeMaskOptions", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-date:not([multiple])',
      templateUrl: './input-date.template.html',
      styleUrls: ['./input-date.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputDateComponent), tuiAsControl(TuiInputDateComponent), tuiDateStreamWithTransformer(TUI_DATE_VALUE_TRANSFORMER), {
        provide: TUI_DROPDOWN_COMPONENT,
        useFactory: () => inject(TUI_IS_MOBILE) && inject(TUI_MOBILE_CALENDAR, InjectFlags.Optional) || inject(TUI_DROPDOWN_COMPONENT, InjectFlags.SkipSelf)
      }]
    }]
  }], function () {
    return [{
      type: i8.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }, {
      type: i0.Type,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_MOBILE_CALENDAR]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_FORMAT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_SEPARATOR]
      }]
    }, {
      type: i9.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_TEXTS]
      }]
    }, {
      type: i2.AbstractTuiValueTransformer,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_DATE_VALUE_TRANSFORMER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_INPUT_DATE_OPTIONS]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }];
  }, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    disabledItemHandler: [{
      type: Input
    }],
    markerHandler: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    defaultActiveYearMonth: [{
      type: Input
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }],
    computeMaskOptions: []
  });
})();
class TuiInputDateDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.computedValue;
  }
  get max() {
    return this.host.computedMax;
  }
  get min() {
    return this.host.computedMin;
  }
  onValueChange(value) {
    if (!value) {
      this.host.nativeValue = '';
    }
    this.host.onValueChange(value);
  }
  process(input) {
    input.inputMode = 'numeric';
  }
}
TuiInputDateDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiInputDateDirective_BaseFactory;
  return function TuiInputDateDirective_Factory(t) {
    return (ɵTuiInputDateDirective_BaseFactory || (ɵTuiInputDateDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputDateDirective)))(t || TuiInputDateDirective);
  };
})();
TuiInputDateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiInputDateDirective,
  selectors: [["tui-input-date", 3, "multiple", ""]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputDateDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input-date:not([multiple])',
      providers: [tuiAsTextfieldHost(TuiInputDateDirective)]
    }]
  }], null, null);
})();
class TuiInputDateModule {}
TuiInputDateModule.ɵfac = function TuiInputDateModule_Factory(t) {
  return new (t || TuiInputDateModule)();
};
TuiInputDateModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputDateModule
});
TuiInputDateModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, MaskitoModule, PolymorpheusModule, TuiWrapperModule, TuiPreventDefaultModule, TuiCalendarModule, TuiSvgModule, TuiLinkModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiValueAccessorModule, TuiLetModule, TuiTextfieldControllerModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MaskitoModule, PolymorpheusModule, TuiWrapperModule, TuiPreventDefaultModule, TuiCalendarModule, TuiSvgModule, TuiLinkModule, TuiHostedDropdownModule, TuiPrimitiveTextfieldModule, TuiValueAccessorModule, TuiLetModule, TuiTextfieldControllerModule],
      declarations: [TuiInputDateComponent, TuiInputDateDirective, TuiNativeDateDirective],
      exports: [TuiInputDateComponent, TuiInputDateDirective, TuiTextfieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiInputDateComponent, TuiInputDateDirective, TuiInputDateModule };
